.code-input input {
	text-transform: uppercase;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	border-radius: 6px;
	border: 1px solid lightgrey;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
	margin: 4px;
	width: 42px;
	height: 52px;
	font-size: 32px;
	box-sizing: border-box;
	color: var(--bg-color);
	background-color: white;
	text-align: center;
	padding: 0;
}

.error{
	font-family: "Poppins", sans-serif;
	font-size: 18px;
	color: var(--negative-color);
	font-weight: 700;
}

.connecting{
	font-family: "Poppins", sans-serif;
	font-size: 18px;
	color: #3b4a6a;
	font-weight: 700;
}

.start {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	padding: 0 40px;
}

.start p{
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: #fff;
	font-weight: 500;
	text-align: center;
	max-width: 400px;
}
.start p b{
	color: var(--primary-color);
}

iframe
{
    /* display: none; */
}

.start-buttons{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.button_container {
	position: relative;
}

.button_container .play{
	width: 144px;
	height: 144px;
	/* border-radius: 50%; */
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
	background: white;
	position: relative;
	z-index: 1;
}

.button_container .play.error{
	color: var(--negative-color);
	font-weight: 500;
}

.button_container .play:active,
.button_container .play:focus-visible {
  transform: translateY(6px);
}

.play_shadow{
	width: 100%;
	height: 100%;
	background-color: lightgray;
	border-radius: 26px;
	position: absolute;
	top: 6px;
	left: 0;
	z-index: 0;
}

.button_container .preview {
	bottom: 100%;
    left: 100%;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    padding: 4px;
    margin: -8px;
	outline: none;
}

.preview img{
	width: 100%;
	height: 100%;
}

.send_progress{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 2;
}

@media (max-width: 486px) {
	.button_container .play {
		width: 122px;
		height: 122px;
		font-size: 16px;
	}
}

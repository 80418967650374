.prank-ideas{
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--primary-color);
	text-align: center;
}

.prank-ideas  h2{
	color: #fff;
}

.prank-ideas summary{
	color: black;
	background: var(--primary-color);
	border-radius: 12px;
	padding: 12px;
}

.prank-ideas h3{
	display: inline;
}

.disclaimer{
	color: black;
	font-size: 16px;
	padding: 12px;
	max-width: 400px;
	border-radius: 12px;
	background: var(--secondary-color);
	box-sizing: border-box;
	margin: 12px;
}

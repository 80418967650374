.footer{
	display: flex;
	gap: 12px;
	margin: 24px;
	margin-top: auto;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.footer_text{
	font-family: "Poppins", sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
}

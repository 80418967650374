.faq{
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--primary-color);
	text-align: center;
}

.faq  h2{
	color: #fff;
}

.faq summary{
	color: black;
	background: var(--primary-color);
	border-radius: 12px;
	padding: 12px;
}

details {
	margin: 12px;
	background: #fff;
	border-radius: 12px;
	color: black;
	cursor: pointer;
	width: 400px;
	max-width: calc(100% - 24px);
}

details p {
	padding: 12px;
}

details > summary::-webkit-details-marker {
	display: none;
}

details > summary {
	list-style: none;
  }

.faq h3{
	display: inline;
}


.faq .email {
	background: none;
	text-decoration: underline;
	padding: 0;
	color: var(--secondary-color);
	font-size: 16px;
	padding: 0 4px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');

h1 {
  text-align: center;
  font-family: "Poppins", monospace;
  font-weight: 700;
  font-style: normal;
  font-size: 44px;
  color:#fff;
  margin: 0;
  padding: 24px;
  text-decoration: none;
}

@media (max-width: 350px) {
  h1 {
    font-size: 40px;
  }
}

html {
  margin: 0;
}

a, button{
  background: var(--primary-color);
  border: none;
  border-radius: 26px;
  padding: 10px 24px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  width: max-content;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.unstyled_button{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.secondary_button{
  background: none;
  color: var(--primary-color);
  padding: 4px 12px;
}

.title{
  margin: 12px auto 0 auto;
}

button.inverted{
  background: var(--secondary-color);
}

a.subtle{
  background: none;
  color: #fff;
  border: 1px solid #fff;
}

body{
  padding: 0;
  margin: 0;
  --bg-color: #0251D0;
  --primary-color: #D9FB23;
  --secondary-color: #FFAA53;
  --negative-color: #FF5E5E;
  background: var(--bg-color);
  overflow-x: hidden;
}

.app{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: 100dvh;
}

.smile{
	  background: url("./assets/img/smile.svg") no-repeat;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.2;
    background-position: 50%;
}

.fiddle{
  background: url("./assets/img/fiddle.png") no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-position: 50%;
  display: none;
}

.smile.top{
	display: none;
}



/*  when we are on a small width <480px */
@media (min-width: 700px) {
	.smile.top{
		display: block;
		background-size: 40vw;
		background-position-x: -10vw;
		background-position-y: -10vh;
	}
	.smile.bot{
		background-size: 40vw;
		background-position-x: 70vw;
		background-position-y: 60vh;
	}
  .fiddle{
    display: block;
    background-size: 60vw;
    background-position-x: -10vw;
    background-position-y: 50vh;
  }
}

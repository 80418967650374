.peers{
	position: absolute;
	top: 0;
	right: 0;
	margin: 12px;
	font-family: "Poppins", sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: var(--primary-color);
	display: flex;
	align-items: center;
	gap: 4px;
	transform-origin: top right;
	transition: color 0.3s;
}

@keyframes peer-error {
	0% {
		transform: scale(1);
		color: var(--primary-color);
	}
	50% {
		transform: scale(2.0);
		color: var(--negative-color);
	}
	100% {
		transform: scale(1);
		color: var(--primary-color);
	}
}

.error-animate {
	animation: peer-error 0.3s;
}

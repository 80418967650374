.privacy_back{
	margin: auto;
}

.privacy_back_last{
	margin: 12px auto 48px auto;
}

.privacy_policy{
	padding: 0 24px;
	display: flex;
    flex-direction: column;
	color: var(--primary-color);
}

.privacy_policy p, .privacy_policy h2, .privacy_policy h3{
	padding: 0 24px;
}

.privacy_policy h2, .privacy_policy h3{
	color: #fff;
}

.privacy_policy .email {
	background: none;
	text-decoration: underline;
	padding: 0;
	color: var(--primary-color);
	font-size: 16px;
}

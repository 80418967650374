.lock{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 100;
}

.lock_background{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: black;
	opacity: 0.0;
	transition: opacity 300ms;
	z-index: 101;
	pointer-events: none;
}

.pullee {
	width: 240px;
	padding: 4px;
	height: 44px;
    appearance: none;
    background: #ffffff76;
    position: fixed;
	border-radius: 24px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.slide_unlock{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 16px;
	margin-top: 2px;
}

.pullee:active::-webkit-slider-thumb {
    transform: scale(1.2);
    cursor: -webkit-grabbing;
}

.pullee:active::-moz-range-thumb {
    transform: scale(1.2);
    cursor: -moz-grabbing;
}

.pullee:active::-ms-thumb {
    transform: scale(1.2);
    cursor: grabbing;
}

.pullee:focus {
    outline: none;
}

.pullee::-webkit-slider-thumb {
    appearance: none;
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: white;
    transform-origin: 50% 50%;
	margin-top:-14px;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: -webkit-grab;
}

.pullee::-moz-range-thumb {
    border: 0;
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: white;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: -moz-grab;
}

.pullee::-ms-thumb {
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: white;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: grab;
}

.pullee::-webkit-slider-runnable-track {
    border-radius: 1rem;
    background-color: transparent;
    height: 1rem;
    padding: 0;
    box-sizing: content-box;
}

.pullee::-moz-range-track {
    border-radius: 1rem;
    background-color: transparent;
    height: 1rem;
    padding: 0;
    box-sizing: content-box;
}

.pullee::-ms-track {
    border: 0;
    border-radius: 1rem;
    background-color: transparent;
    height: 1rem;
    padding: 0;
    box-sizing: content-box;
    color: transparent;
}

.pullee::-ms-fill-lower,
.pullee::-ms-fill-upper {
    background-color: transparent;
}

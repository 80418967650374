.sound-trivia{
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--primary-color);
	text-align: center;
}

.sound-trivia  h2{
	color: #fff;
}

.sound-trivia summary{
	color: black;
	background: var(--primary-color);
	border-radius: 12px;
	padding: 12px;
}

.sound-trivia h3{
	display: inline;
}

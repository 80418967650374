.categories{
	display: flex;
    gap: 12px;
    overflow-x: scroll;
    padding: 0px 16px;
    box-sizing: border-box;
	max-width: 100%;

	-ms-overflow-style: none;
	scrollbar-width: none;
	white-space: nowrap;

	&::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
}

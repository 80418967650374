.sfx_holder{
	display: flex;
	flex-wrap: wrap;
    gap: 52px;
    justify-content: center;
    align-items: center;
	padding: 24px;
	margin-top: 24px;
	margin-bottom: 48px;
}

.lobby{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -24px;
}

.lobby_code{
	position: relative;
	text-align: center;
	font-family: "Poppins", monospace;
	font-weight: 700;
	font-style: normal;
	font-size: 32px;
	color: var(--bg-color);
	padding: 0;
	margin: 24px;
	margin-bottom: 36px;
	background: #fff;
	border-radius: 50px;
	padding: 0 24px;
	letter-spacing: 2px;
}

.lobby_qr{
	margin-bottom: 32px;
}

.lobby_qr img{
	width: 200px;
	height: 200px;
	border-radius: 16px;
}

.lobby_back{
	position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 12px;
	border-radius: 50px;
	padding: 10px 12px;
	font-size: 14px;
}

.qr, .share, .lock_screen{
	position: absolute;
	left: -90px;
	top: 50%;
	transform: translateY(-50%);
	padding: 12px;
    box-sizing: border-box;
	cursor: pointer;
	outline: none;
}

.qr.desktop{
	left: -60px;
}

.qr.active{
	fill: var(--secondary-color);
}

.share {
	left: -52px;
}

.popup{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;
	background: var(--bg-color);
	border-radius: 24px;
	padding: 24px;
	color: var(--primary-color);
	min-width: 240px;
}

.popup h3{
	color: #fff;
	margin: 0;
}

.popup_blackout{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	min-height: 100dvh;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99;
}

.popup img{
	width: 140px;
	display: block;
	margin-bottom: 12px;
	transform: translateX(12px);
}

.lock_screen{
	left: 214px;
    padding: 14px;
}

